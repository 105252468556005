import React from 'react';
import { Button, Form, Input, Modal, Result, Select } from 'antd';
import { useApolloClient, useMutation } from '@apollo/client';
import Lottie from 'react-lottie-player';
import RECONCIALITION_REASON_LIST from 'norbr-shared-lib/constants/transaction/reconciliationReasons/list';
import WindowCloseIcon from '@2fd/ant-design-icons/lib/WindowClose';
import validationAnimation from '../../../../../../assets/icons/used/000-validation-green.json';
import { reconcileMutation } from '../query';
import styles from './MatcherModals.module.scss';
import { delay } from '../../../../../../util/utils';

const ReconciliationModal = ({ operationId, reconciliationCandidate, onClose }) => {
  const client = useApolloClient();
  const [reconcile, { called, error, loading }] = useMutation(reconcileMutation, {
    onCompleted: async () => {
      // Refetch queries with delay
      await delay(1000);
      await client.refetchQueries({ include: ['MatcherOrders', 'ActivityMatcher'] });
      onClose();
    },
    errorPolicy: 'all',
  });

  const [form] = Form.useForm();

  const submit = ({ reason, note }) => {
    reconcile({
      variables: {
        type: 'manual',
        operationId,
        pspOperationId: reconciliationCandidate,
        reason,
        note,
      },
    });
  };

  if (error) {
    return (
      <Modal destroyOnClose open onCancel={onClose} footer={false}>
        <Result status="error" title="An error occurred" subTitle={error.message} />
      </Modal>
    );
  }

  if (called && !loading) {
    return (
      <Modal destroyOnClose open onCancel={onClose} footer={false}>
        <Result
          icon={
            <Lottie
              loop={false}
              animationData={validationAnimation}
              play
              style={{ width: 150, height: 150, margin: 'auto' }}
            />
          }
          status="success"
          title="Matched"
          subTitle={
            <div>
              Operation <b>{operationId}</b> and <b>{reconciliationCandidate}</b> have been successfully matched.
              <br />
              <br />
              Please note that it might take a few minutes to be updated in the Merchant Console.
            </div>
          }
        />
      </Modal>
    );
  }

  return (
    <Modal
      className={styles.modal}
      title="MATCH DETAILS"
      closeIcon={<WindowCloseIcon />}
      open={!!reconciliationCandidate}
      footer={
        <div className={styles.footer}>
          <Button onClick={onClose} disabled={loading}>
            CANCEL
          </Button>
          <Button type="primary" onClick={form.submit} loading={loading}>
            SAVE
          </Button>
        </div>
      }
      onCancel={onClose}
    >
      <Form form={form} layout="vertical" onFinish={submit}>
        <Form.Item name="reason" label="Matching reason" rules={[{ required: true, message: 'Reason is required' }]}>
          <Select options={RECONCIALITION_REASON_LIST.map(({ id, label }) => ({ value: id, label }))} />
        </Form.Item>
        <Form.Item name="note" label="Comments">
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ReconciliationModal;
